import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1b889c22"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "pageList",
  class: "KxqrList"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { style: {"font-size":"14px","font-weight":"bold","color":"#2c3e50"} }
const _hoisted_4 = { style: {"cursor":"pointer"} }
const _hoisted_5 = { style: {"font-size":"18px","font-weight":"bold","color":"red"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ListEngine = _resolveComponent("ListEngine")!
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ListEngine, {
      pageList: _ctx.pageList,
      ref: "pageListRef",
      onQrHandler: _cache[5] || (_cache[5] = ($event: any) => (_ctx.utilInst.qrHandler()))
    }, {
      queryParams: _withCtx(() => [
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("客户名称：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[0] || (_cache[0] = e => _ctx.pageList.queryParam.customerName = _ctx.valid(e)),
                  placeholder: "请输入客户名称",
                  modelValue: _ctx.pageList.queryParam.customerName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageList.queryParam.customerName) = $event)),
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("订单号：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[2] || (_cache[2] = e => _ctx.pageList.queryParam.code = _ctx.valid(e)),
                  placeholder: "请输入订单号",
                  modelValue: _ctx.pageList.queryParam.code,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pageList.queryParam.code) = $event)),
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("确认类别：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.pageList.queryParam.srcType,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pageList.queryParam.srcType) = $event)),
                  placeholder: "请选择确认类别",
                  style: {"width":"100%"},
                  clearable: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: "TT支付",
                      value: "0"
                    }),
                    _createVNode(_component_el_option, {
                      label: "信用证支付",
                      value: "1"
                    }),
                    _createVNode(_component_el_option, {
                      label: "OA支付",
                      value: "2"
                    }),
                    _createVNode(_component_el_option, {
                      label: "信保回款",
                      value: "3"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      extendSlot: _withCtx(() => []),
      tbCols: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "F_ORDER_CODE",
          label: "订单号",
          fixed: "left",
          width: "100"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_tag, {
              onClick: ($event: any) => (_ctx.utilInst.piDetail(scope.row.F_PI_ID)),
              style: {"cursor":"pointer"},
              title: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(scope.row.F_ORDER_CODE), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_CUSTOMER_NAME",
          label: "客户名称"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_COUNTRY_NAME",
          label: "国家"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_JS_TYPE",
          label: "结算币种",
          width: "80"
        }),
        _createVNode(_component_el_table_column, {
          prop: "_F_SRC_TYPE",
          label: "确认类别",
          width: "150"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", {
              style: {"font-size":"14px","font-weight":"bold","color":"#2c3e50","cursor":"pointer"},
              onClick: ($event: any) => (_ctx.utilInst.viewBill(scope.row.F_PAY_ID,scope.row.F_SRC_TYPE))
            }, _toDisplayString(scope.row._F_SRC_TYPE), 9, _hoisted_2)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_AMOUNT",
          label: "确认金额",
          "header-align": "center",
          align: "right",
          width: "150"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.utils.UtilPub.toAmountFormat(scope.row.F_AMOUNT,false,2)), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["pageList"]),
    _createVNode(_component_el_dialog, {
      title: "确认",
      modelValue: _ctx.otherParams.dialogVisible,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.otherParams.dialogVisible) = $event)),
      draggable: "",
      "append-to-body": ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", null, [
          _createVNode(_component_el_button, {
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.otherParams.dialogVisible=false))
          }, {
            default: _withCtx(() => [
              _createTextVNode("取 消")
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.utilInst.doQr()))
          }, {
            default: _withCtx(() => [
              _createTextVNode("确 定")
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_descriptions, {
              border: "",
              column: 1
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_descriptions_item, { label: "订单号" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_el_tag, {
                        size: "small",
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.utilInst.piDetail(_ctx.otherParams.selInfo.F_PI_ID)))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.otherParams.selInfo.F_ORDER_CODE), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_descriptions_item, { label: "确认类别" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.otherParams.selInfo._F_SRC_TYPE), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_descriptions_item, { label: "确认金额" }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.utils.UtilPub.toAmountFormat(_ctx.otherParams.selInfo.F_AMOUNT,false,2)) + " " + _toDisplayString(_ctx.otherParams.selInfo.F_JS_TYPE), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}